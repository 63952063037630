import React from "react";
import Logo from "../assets/logo.png";
import { UserLogin } from "./UserLogin";

export const PreLoginNavigation = () => {
  function myFunction() {
    let x = document.getElementById("myDropdown");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
  return (
    <div>
      <div class="navbar bg-gray-900">
        <div class="navbar-start">
          <div class="dropdown">
            <label tabindex="0" class="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </label>
            <ul
              tabindex="0"
              class="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <a href="/Calendar">Calendar</a>
              </li>
              <li tabindex="0">
                <a class="justify-between">
                  About
                  <svg
                    class="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                  </svg>
                </a>
                <ul class="p-2 z-50">
                  <li>
                    <a href="/projects">Projects</a>
                  </li>
                  <li>
                    <a href="/About">About</a>
                  </li>
                </ul>
              </li>
              {/* <li>
                <a href="/Admin">Admin</a>
              </li> */}
            </ul>
          </div>
          <a href="/" class="btn btn-ghost normal-case text-xl">
            <img className="h-12" src={Logo} />
          </a>
        </div>
        <div class="navbar-center hidden lg:flex">
          <ul class="menu menu-horizontal p-0">
            {/* <li>
              <a href="/Calendar">Calendar</a>
            </li>
            <li>
              <a href="/personalFeed">Personal Feed</a>
            </li> */}
            {/* <li>
              <a href="/clubs">Clubs</a>
            </li> */}
            <li tabindex="0">
              <a href="/Contact">Contact</a>

              <a href="/About">Meet Our Team</a>
            </li>
            {/* <li>
              <a href="/Admin">Admin</a>
            </li> */}
          </ul>
        </div>
        <div class="navbar-end mx-2">
          <UserLogin />
        </div>
      </div>
    </div>
  );
};
