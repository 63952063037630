import React from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { LocationPin } from "./LocationPin";

export const SimpleMap = (props) => {
  const libraries = ["places"];

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDJR0XomP96Upl_rEZswi6V5hbtjPU59Bc",
    libraries,
  });

  if (loadError) return "error loading map";
  if (!isLoaded) return "Loading";

  const center = {
    lat: props.lat,
    lng: props.lng,
  };

  const mapContainerStyle = {
    width: "90vw",
    height: "50vh",
  };
  if (props.locationExists === false) {
    return <div>No location data</div>;
  }

  return (
    // Important! Always set the container height explicitly
    <div className="flex justify-center my-10">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={center}
      >
        <LocationPin lat={center.lat} lng={center.lng} text="asdjflsdjf" />
      </GoogleMap>
    </div>
  );
};
