import React, { useState, useEffect } from "react";
import { bounceInUp, bounceInLeft, bounceInRight } from "react-animations";
import styled, { keyframes } from "styled-components";
import Logo from "../assets/logo.png";
import {isMobile} from 'react-device-detect';
import { UserLogin } from "./UserLogin";
import Hero from "../assets/Hero.svg";
import Hero2 from "../assets/Hero2.svg";
import ChatGPT from "../assets/ChatGPT.png"
import Downarrow from "../assets/Downarrow.svg";
import "animate.css/animate.min.css";
import firebase from "firebase/compat/app";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { AiOutlineArrowDown } from "react-icons/ai";
import { v4 as uuidV4 } from "uuid"
import { CalendarEvent } from "../pages/CalendarEvent";

export const HomeComponent = () => {
  const newDocuemntUrl = `/newEvent/${uuidV4()}`
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [data, setData] = useState([]);
  const BounceInUp = styled.div`
    animation: 2s ${keyframes`${bounceInUp}`};
  `;
  firebase.auth().onAuthStateChanged(function (user) {
    if (user && !userLoggedIn) {
      setUserLoggedIn(true);
    }
  });
  const submit = async () => {
    setSearchButtonClicked(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question: searchInput,
        }),
      };
  
      const response = await fetch(
        `https://loop-ruby.vercel.app/inclineprep/chatgpt/search`,
        requestOptions
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const json = await response.json();
      setSearchResults(json);
  
    } catch (error) {
      console.error("There was an error", error);
    }
  };

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var splitDate = date.toString().split(" ");
    var dt = splitDate[2];
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  const getDayOfWeek = (date) => {
    var splitDate = date.toString().split(" ");
    var dayOfWeek = splitDate[0];
    return dayOfWeek;
  };

  const fetchIndividualEventData = async (eventId) => {
    try {
      const response = await fetch(`https://loop-ruby.vercel.app/inclineprep/oneEvent/${eventId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching individual event data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchDataForIds = async () => {
      try {
        const promises = searchResults.map(async (id) => {
          const data = await fetchIndividualEventData(id);
          return data;
        });
        
        const results = await Promise.all(promises);
        setData(results);
      } catch (error) {
        console.error("Error fetching individual event data:", error);
      }
    };
  
    fetchDataForIds();
  }, [searchResults]);

  return (
    <div className="bg-gray-900">
      <section class="">
        <div class="px-4 pt-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 lg:grid-cols-2">
            <div class="relative z-10 lg:py-16 lg:order-last">
              <div class="relative h-64 lg:h-full bg-gray-900">
                <img
                  class="absolute inset-0 object-cover h-76 lg:h-max mx-auto"
                  src={Hero}
                  // src="https://images.unsplash.com/photo-1531497865144-0464ef8fb9a9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                  alt="Indoors house"
                />
              </div>
            </div>
            <div class="relative items-center bg-gray-900">
              {/* <span class="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-gray-100 lg:block lg:-left-16"></span> */}

              <div class="p-8 sm:p-16 lg:p-24">
                <h1 class="sm:text-sm font-extrabold lg:text-3xl text-white">
                  {/* <img className="h-32" src={Logo} /> */}
                  <br />
                  <strong class="text-primary-color sm:block text-4xl lg:text-7xl font-extrabold text-left">
                    Helping Students Stay in the Loop
                  </strong>
                </h1>

                <p class="max-w-lg mt-4 sm:leading-relaxed sm:text-xl text-left">
                  An interactive calendar helping you search, filter and find
                  events at your school
                </p>
                <div class="flex flex-wrap gap-4 mt-8 text-center">
                  <a
                    href={newDocuemntUrl}
                    class="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-primary-color sm:w-auto active:bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring"
                  >
                    New Event
                  </a>

                  <a
                    class="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-black sm:w-auto hover:text-black active:text-black focus:outline-none focus:ring"
                    href="/about"
                  >
                    Learn More
                  </a>
                </div>
                {userLoggedIn && (
                  <>
                  <div className={`${!isMobile ? "flex-column flex items-center": ""}`}>
                  <form className="w-full mt-10 mr-2">
                    <label
                      htmlFor="default-search"
                      className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
                    >
                      search
                    </label>
                    <div className="relative">
                      <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="search"
                        id="default-search"
                        className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Ex: I want to join a coding club"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                  </form>
                  <button
                    disabled={!searchInput}
                    onClick={() => {
                      submit()
                    }}
                    className={`block px-10 py-4 mt-10 text-sm text-white rounded shadow ${searchInput ? "bg-primary-color active:bg-yellow-500 hover:bg-yellow-700 cursor-pointer" : "bg-slate-500"} sm:w-auto focus:outline-none focus:ring`}
                  >
                    Search
                  </button>
                </div> 
                <div className="flex flex-row">  
                <img src={ChatGPT} className="w-5 h-auto mt-6" />             
                <p class="max-w-lg mt-6 sm:leading-relaxed text-xs text-left ml-2">
                  Powered by ChatGPT
                </p>
                </div>
                {data.length === 0 && searchButtonClicked && (
                  <div
                  class="h-8 w-8 mt-10 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                  <span
                    class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                    >Loading...</span
                  >
                  </div>  
                )}
                </>
                )}
                {/* <a
                  class="inline-block px-12 py-3 mt-8 text-sm font-medium text-white bg-primary-color border border-primary-color rounded active:text-white hover:bg-yellow-500 hover:text-white focus:outline-none focus:ring"
                  href="/"
                >
                  About Us
                </a> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex mx-auto justify-center items-center">
          <AiOutlineArrowDown />
        </div> */}
      </section>
      {data.length > 0 && (
        <section className="text-white bg-gray-900">
          <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-1">
              {data.map((event) => (
                <div key={event._id}>
                  <CalendarEvent
                    title={event.title}
                    description={event.description}
                    createdAt={event.createdAt}
                    dateToString={dateToString}
                    slug={event.slug}
                    image={event.image}
                    category={event.category}
                    emoji={event.emoji}
                    id={event._id}
                    location={event.location}
                    getDayOfWeek={getDayOfWeek}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {/* <div className="bg-gray-900">
        <img src={Downarrow} />
      </div> */}
      {/* <BounceInLeft> */}
      <AnimationOnScroll animateIn="animate__bounceInRight">
        <section className="bg-gray-900 mt-20">
          <div class="px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 lg:grid-cols-2 lg:h-screen">
              <div class="relative z-10">
                <div class="relative h-64 sm:h-80 lg:h-full bg-gray-900">
                  <img
                    class="absolute inset-0 object-cover h-76 lg:h-3/4 mx-auto"
                    src={Hero2}
                    // src="https://images.unsplash.com/photo-1531497865144-0464ef8fb9a9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                    alt="Indoors house"
                  />
                </div>
              </div>

              <div class="relative flex items-center bg-gray-900">
                {/* <span class="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-gray-100 lg:block lg:-left-16"></span> */}

                <div class="p-8 sm:p-16 lg:p-24">
                  <h2 class="text-2xl font-bold sm:text-5xl text-primary-color">
                    Find Club, Social and Professional Events Faster
                  </h2>

                  <p class="mt-4 text-gray-100">
                    Our platform integrates every event at your school in one
                    place so you can easily search, view updates and attend
                    events that you are interested in.
                  </p>

                  {/* <a
                  class="inline-block px-12 py-3 mt-8 text-sm font-medium text-white bg-primary-color border border-primary-color rounded active:text-white hover:bg-yellow-500 hover:text-white focus:outline-none focus:ring"
                  href="/"
                >
                  About Us
                </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimationOnScroll>

      {/* </BounceInLeft>
  <BounceInRight> */}
    </div>
  );
};
