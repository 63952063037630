import React, { useState } from "react";
import { atcb_action } from "add-to-calendar-button";

export const CalendarButton = (props) => {
  const [name, setName] = useState("Some event");
  const changeName = (e) => {
    setName(e.target.value);
  };
  console.log(props.startTime);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        atcb_action({
          name: props.title,
          description: props.description,
          location: props.location,
          startDate: props.startDate,
          endDate: props.endDate,
          startTime: props.startTime,
          endTime: props.endTime,
          options: [
            "Apple",
            "Google",
            "iCal",
            "Microsoft365",
            "Outlook.com",
            "Yahoo",
          ],
          timeZone: "America/Denver",
          iCalFileName: "Reminder-Event",
        });
      }}
    >
      <input
        type="submit"
        value="Add To Calendar"
        className="btn float-left border-none bg-gray-800 hover:bg-gray-700 mx-2"
      />
    </form>
  );
};
