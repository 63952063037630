import React, { useEffect } from "react";
import { SimpleMap } from "./Map/SimpleMap";
import { useState } from "react";
import { LoadingPage } from "./LoadingPage";
import { CalendarButton } from "./calendarButton";

export const EventPageComponent = (props) => {
  const [isInterested, setIsInterested] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [peopleGoing, setPeopleGoing] = useState();

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var splitDate = date.toString().split(" ");
    var dt = splitDate[2];
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `https://loop-ruby.vercel.app/inclineprep/oneEvent/${props.id}`
      )
        .then((res) => res.json())
        .then((json) => setData(json));
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (data) {
      setPeopleGoing(data.peopleGoing);
    }
  }, [data]);

  const startTime = new Date(props.startTime);
  const endTime = new Date(props.endTime);
  const startDateTextFull = startTime.toTimeString().split(" ")[0];
  const startDateText = startDateTextFull.substring(
    0,
    startDateTextFull.length - 3
  );
  const endDateTextFull = endTime.toTimeString().split(" ")[0];
  const endDateText = endDateTextFull.substring(0, endDateTextFull.length - 3);

  console.log(startDateText);
  // console.log(dateToString(startTime));
  // console.log(startTime.getHours());
  // console.log(startTime.getMinutes());
  // console.log(`${startTime.getHours()}:${startTime.getMinutes()}`);

  if (isLoading) {
    return <LoadingPage />;
  }

  var date = new Date(props.startTime);

  let buttonClick = () => {
    // still need to implement way to prevent same person clicking infinite times
    // have to use localStorage to see
    if (!isInterested) {
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
      };
      fetch(
        `https://loop-ruby.vercel.app/inclineprep/interested/${props.id}`,
        requestOptions
      )
        // .then(alert("success"))
        .catch((error) => {
          console.error("there was an error", error);
        });
      setIsInterested(true);
    }
  };

  var fullDate = props.dateToString(date);
  return (
    <div className="mx-10 h-full shadow-2xl lg:px-10 lg:py-10 bg-gray-900">
      <div className="flex justify-center">
        {/* <img
          class="object-center object-cover w-full h-60 bg-gradient-to-r from-black to-transparent"
          src={props.image}
          alt=""
        /> */}
      </div>
      <div class="block overflow-hidden rounded-lg shadow-sm">
        <div class="p-6">
          <div class="flex space-x-2 items-center">
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
            <p class="mt-2 text-lg text-left text-gray-600">{props.location}</p>
          </div>
          <div class="flow-root">
            <p class="text-4xl font-bold float-left">{props.title}</p>
            <br />
            <p class="text-4xl font-bold float-right text-gray-600 my-4">
              {fullDate}
            </p>
          </div>
          <div class="flow-root">
            <button
              className="btn float-left border-none bg-gray-800 hover:bg-gray-700"
              onClick={buttonClick}
            >
              Interested
            </button>
            <CalendarButton
              title={props.title}
              description={props.description}
              location={props.location}
              startDate={dateToString(startTime)}
              endDate={dateToString(endTime)}
              startTime={startDateText}
              endTime={endDateText}
            />
            <br />
            <br />

            <p className="text-xl float-left text-gray-600 text-left mb-10 mt-5">
              # Students Interested: {peopleGoing}
              <br />
              Start Time: {startDateText} | End Time: {endDateText}
            </p>
            <p class="text-xl float-left text-gray-600 text-left">
              {props.description}
            </p>

            {/* <p class="text-xl float-right text-gray-600">{fullDate}</p> */}
          </div>
          {/* <p class="mt-2 text-lg text-left justify-start text-gray-600">
            Add description here
          </p> */}
          <SimpleMap lat={51.048615} lng={-114.070847} locationExists={true} />
        </div>
      </div>
    </div>
  );
};
