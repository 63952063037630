import React from "react";
import { CalendarEvent } from "../pages/CalendarEvent";

export const DayOfEvents = (props) => {
  var date = new Date(props.date);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const getDayOfWeek = (date) => {
    var splitDate = date.toString().split(" ");
    var dayOfWeek = splitDate[0];
    return dayOfWeek;
  };
  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var splitDate = date.toString().split(" ");
    var dt = splitDate[2];
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  console.log("filtered data: ");
  const events = props.filteredData;
  console.log(events);
  return (
    <div>
      <h2 className="mt-5 text-left divide-y">
        {days[date.getUTCDay()]} - {props.date}
      </h2>
      <div className="divide-y divide-gray-500">
        {events.map((event) => {
          return (
            <div>
              <CalendarEvent
                title={event.title}
                description={event.description}
                createdAt={event.createdAt}
                slug={event.slug}
                image={event.image}
                category={event.category}
                emoji={event.emoji}
                location={event.location}
                id={event._id}
                getDayOfWeek={getDayOfWeek}
                dateToString={dateToString}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
