import React, { useEffect, useState } from "react";
import { AdminApproveCard } from "../components/AdminComponents/AdminApproveCard";
import AdminLogin from "../components/AdminComponents/AdminLogin";

const Admin = (props) => {
  const admin = sessionStorage.getItem("admin");

  const [date, setDate] = useState(new Date());
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `https://loop-ruby.vercel.app/inclineprep/events`
      )
        .then((res) => res.json())
        .then((json) => setData(json));
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, []);

  if (admin != "loggedIn") {
    return <AdminLogin />;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  var notAdminApproved = data.filter(function (el) {
    return el.adminApproved == false;
  });

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  return (
    <div className="bg-gray-900">
      {" "}
      <div>
        <h1 class="text-5xl my-10">Unapproved Events</h1>
        <div>
          <div class="max-w-screen-xl py-16 mx-auto flex lg:items-center">
            <div class="mx-auto text-center">
              <div class="grid grid-cols-1">
                {notAdminApproved.map((event) => (
                  <AdminApproveCard
                    title={event.title}
                    description={event.description}
                    createdAt={event.createdAt}
                    dateToString={dateToString}
                    slug={event.slug}
                    image={event.image}
                    id={event._id}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="my-10">
        <a
          class="inline-block px-8 py-3 text-sm font-medium text-white transition bg-indigo-600 rounded hover:scale-110 hover:shadow-xl active:bg-indigo-500 focus:outline-none focus:ring"
          href="/adminSignup"
        >
          Add New Admin Login
        </a>
      </div>
    </div>
  );
};

export default Admin;
