import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventPageComponent } from "../components/EventPageComponent";
import { LoadingPage } from "../components/LoadingPage";

export const EventPage = () => {
  const { slug } = useParams();
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);
  const startPath = "https://loop-ruby.vercel.app/inclineprep";

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`${startPath}/oneEvent/${slug}`)
        .then((res) => res.json())
        .then((json) => setData(json));
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, []);

  if (isLoading) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }
  console.log(data);

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  return (
    <div className="flex justify-center h-max bg-gray-900">
      <div className="lg:w-3/4 w-max">
        <EventPageComponent
          title={data.title}
          description={data.description}
          createdAt={data.createdAt}
          dateToString={dateToString}
          image={data.image}
          location={data.location}
          slug={slug}
          id={data._id}
          startTime={data.startTime}
          endTime={data.endTime}
        />
        {/* <div>
          <SimpleMap lat={51.048615} lng={-114.070847} locationExists={true} />
        </div> */}
      </div>
    </div>
  );
};
