import logo from "./logo.svg";
import "./App.css";
import { Navigation } from "./components/Navigation";
import { Home } from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { EventDetailsPage } from "./components/EventDetailsPage";
import { PageNotFound } from "./pages/PageNotFound";
import { TestComponent } from "./components/TestComponent.jsx";
import { Footer } from "./components/Footer";
import { CalendarPage } from "./pages/CalendarPage";
import { GoogleCalendar } from "./pages/GoogleCalendar";
import { Test } from "./pages/Test";
import { NewEvent } from "./pages/NewEvent";
import { AgendaView } from "./pages/AgendaView";
import Admin from "./pages/Admin";
import { AdminSignup } from "./components/AdminComponents/AdminSignup";
import { EventPage } from "./pages/EventPage";
import { About } from "./pages/About";
import { NewCalendar } from "./pages/NewCalendar";
import { ListView } from "./pages/ListView";
import { PersonalizedPage } from "./pages/PersonalizedPage";
import { ClubsPage } from "./pages/ClubsPage";
import { NewHome } from "./components/newUI/NewHome";
import firebase from "firebase/compat/app";
import { PreLoginNavigation } from "./components/PreLoginNavigation";
import { useState } from "react";
import { LoadingPage } from "./components/LoadingPage";

function App() {
  const [loaded, setLoaded] = useState(false);
  const [navigationComponent, setNavigationComponent] = useState(
    <PreLoginNavigation />
  );
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      setNavigationComponent(<Navigation />);
    }
    setLoaded(true);
  });
  if (!loaded) {
    return <LoadingPage />;
  }
  return (
    <div className="App bg-gray-900">
      {navigationComponent}
      <Router>
        <Routes>
          <Route eact path="/" exact element={<Home />} />
          <Route path="/calendar/eventDetails" element={<EventDetailsPage />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/test_component" element={<TestComponent />} />
          {/* <Route path="/calendar" element={<CalendarPage />} /> */}
          {/* <Route path="/google-calendar" element={<GoogleCalendar />} /> */}
          {/* <Route path="/test" element={<Test />} /> */}
          <Route path="/newEvent/:id" element={<NewEvent />} />
          <Route path="/agenda" element={<AgendaView />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/adminSignup" element={<AdminSignup />} />
          <Route path="/eventpage" element={<EventPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/events/:slug" element={<EventPage />} />
          <Route path="/Calendar" element={<NewCalendar />} />
          <Route path="/List" element={<ListView />} />
          <Route path="/personalFeed" element={<PersonalizedPage />} />
          <Route path="/clubs" element={<ClubsPage />} />
          <Route path="/newHome" element={<NewHome />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
