import React from "react";
import HeroImage from "../../assets/Hero.svg";

export const NewHome = () => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div>
        <h2 className="text-bookmark-blue text-3xl md:text-4 lg:text-5xl text-center lg:text-left mb-6 ml-20 mt-64 font-coves">
          Helping Students Stay in the Loop
        </h2>
      </div>
      <div>
        <img src={HeroImage}></img>
      </div>
    </div>
  );
};
