import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

export const InterestPickerButton = (props) => {
  const [clicked, setClicked] = useState(false);
  const [buttonStyling, setButtonStyling] = useState(
    "block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-primary-color sm:w-auto active:bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring"
  );

  // need to be able to change the button based on club or subject button

  let newArray = props.data;
  const removeValue = (value) => {
    const index = newArray.indexOf(value);
    if (index > -1) {
      newArray.splice(index, 1);
    }
  };

  useEffect(() => {
    if (clicked) {
      setButtonStyling(
        "w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-primary-color sm:w-auto active:bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring"
      );
      newArray.push(props.content);
      props.passChildData(newArray);
    } else {
      setButtonStyling(
        "w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-gray-500 sm:w-auto active:bg-gray-400 hover:bg-gray-400 focus:outline-none focus:ring"
      );
      removeValue(props.content);
    }
  }, [clicked]);

  console.log(clicked);
  return (
    <div>
      <button onClick={() => setClicked(!clicked)} className={buttonStyling}>
        {props.content}
      </button>
    </div>
  );
};

export const InterestPicker = () => {
  const [fetchedData, setFetchedData] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [clubList, setClubList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState();
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);

  // need to get the user ID still
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      setUid(user.uid);
    });
  });

  const submit = () => {
    console.log();
    const test = JSON.stringify({
      userId: uid,
      likedClubs: selectedClubs,
      subjects: selectedSubjects,
    });
    console.log(test);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: uid,
        likedClubs: selectedClubs,
        subjects: selectedSubjects,
      }),
    };
    fetch(
      `https://loop-ruby.vercel.app/inclineprep/userInterests`,
      requestOptions
    )
      .then(alert("success"))
      .catch((error) => {
        console.error("there was an error", error);
      });
    console.log("success");
    window.location.reload(false);
  };

  useEffect(() => {
    const data = fetch("https://loop-ruby.vercel.app/inclineprep/clubs")
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        if (json != null) {
          for (let i = 0; i < json.length; i++) {
            let club = json[i].clubName;
            let category = json[i].category;
            if (!categoryList.includes(category)) {
              categoryList.push(category);
            }
            if (!clubList.includes(club)) {
              clubList.push(club);
            }
          }
          console.log(clubList);
          console.log(categoryList);
          setLoading(false);
        } else {
          console.log("No clubs found");
        }
      });
  }, []);

  if (!loading) {
    let clubContent = [];
    let categoryContent = [];
    for (let item of clubList) {
      clubContent.push(
        <InterestPickerButton
          data={selectedClubs}
          content={item}
          passChildData={setSelectedClubs}
        />
      );
    }
    for (let item of categoryList) {
      categoryContent.push(
        <InterestPickerButton
          content={item}
          passChildData={setSelectedSubjects}
          data={selectedSubjects}
        />
      );
    }
    return (
      <div class="max-w-screen-xl  py-16 mx-auto lg:items-center lg:flex">
        <div class="max-w-xl mx-auto text-center">
          {/* should have a popup for the instructions */}
          {/* <h1 className="text-xl text-left my-10">
            Select your favourite clubs and categories then click submit
          </h1> */}
          <h1 className="text-2xl text-left">Clubs:</h1>
          <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-8 my-5">
            {clubContent}
          </div>

          <h1 className="text-2xl mt-20 text-left">Categories</h1>
          <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-8 mt-5 mb-20">
            {categoryContent}
          </div>
          <button
            onClick={submit}
            className="px-12 py-3 mb-20 text-sm font-medium text-white rounded shadow bg-primary-color sm:w-auto active:bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring"
          >
            Submit
          </button>
        </div>
      </div>
      //   <div className="bg-gray-900 h-screen">
      //     <div className="grid grid-cols-4 gap-4 mx-20">
      //       {clubContent}

      //       {categoryContent}
      //       <br></br>
      // <button
      //   onClick={submit}
      //   className="block px-12 py-3 text-sm font-medium text-white rounded shadow bg-gray-700 sm:w-auto active:bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring"
      // >
      //   Done
      // </button>
      //     </div>
      //   </div>
    );
  }

  //   firebase.auth().onAuthStateChanged(function (user) {
  //     if (user) {
  //       const data = fetch(
  //         `https://loop-ruby.vercel.app/inclineprep/userInterests/${user.uid}`
  //       )
  //         .then((res) => res.json())
  //         .then((json) => {
  //           console.log(json);
  //         });
  //     }
  //   });

  return <div>InterestPicker</div>;
};
