import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Picker from "emoji-picker-react";
import BasicDateTimePicker from "../components/BasicDateTimePicker";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";

export const NewEvent = ({ children }) => {
  const { id: documentId} = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  let [image, setImage] = useState("");
  const navigate = useNavigate();
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [category, setCategory] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [socket, setSocket] = useState();
  const SAVE_INTERVAL_MS = 2000;

  const startTimeHandler = (newValue) => {
    setStartTime(newValue);
  };

  const endTimeHandler = (newValue) => {
    setEndTime(newValue);
  };

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    image = image.slice(0, -2);
    console.log(image);
    let newArticle = JSON.stringify({
      title: title,
      description: description,
      startTime: startTime,
      endTime: endTime,
      // image: image + "10",
      emoji: chosenEmoji.emoji,
      location: location,
      category: category,
    });
    console.log(newArticle);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: newArticle,
    };
    let res = await fetch(
      "https://loop-ruby.vercel.app/inclineprep/newEvent",
      requestOptions
    );
    if (res.status === 200) {
      console.log("success");
    }

    //reset form
    setTitle("");
    setDescription("");
    // setCreatedAt("");
    setImage("");
    console.log("success");
    alert("success");
    navigate("/calendar");
  };

  useEffect(() => {
    console.log(documentId)
    const socket = io(`https://loop-ruby.vercel.app`, { transports : ['websocket'] });
    setSocket(socket);
    return () => {
      socket.disconnect();
    }
  }, []);

  useEffect(() => {
    if (!socket) return;
    socket.once('load-document', document => {
      console.log(document)
      setTitle(document.title)
      setDescription(document.description)
      setLocation(document.location)
      setCategory(document.category)
      setStartTime(document.startTime)
      setEndTime(document.endTime)
      setChosenEmoji(document.emoji)
    })
    socket.emit('get-document', documentId)
  }, [socket, documentId])


  useEffect(() => {
    if (!socket) return;

    const interval = setInterval(() => {
      socket.emit('save-document',
        {
          title,
          description,
          location,
          category,
          startTime,
          endTime,
          chosenEmoji,
        }
      )
    }, SAVE_INTERVAL_MS)

    return () => {
      clearInterval(interval)
    }
  }, [title, description, location, category, startTime, endTime, chosenEmoji]);


  return (
    <div>
      <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div class="max-w-lg mx-auto">
          <form
            onSubmit={handleSubmit}
            class="p-8 mt-6 mb-0 space-y-4 rounded-lg shadow-2xl bg-white"
          >
            <p class="text-lg font-medium">Add New Event</p>

            <div>
              <div class="relative mt-1">
                <input
                  type="text"
                  id="title"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />

                <span class="absolute inset-y-0 inline-flex items-center right-4">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                  />
                </span>
              </div>
            </div>

            <div>
              <div class="relative my-1">
                <input
                  type="text"
                  id="description"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <span class="absolute inset-y-0 inline-flex items-center right-4">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </span>
              </div>
            </div>

            <div>
              <div class="relative mt-1">
                <input
                  type="text"
                  id="location"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />

                <span class="absolute inset-y-0 inline-flex items-center right-4">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </span>
              </div>
            </div>
            <div>
              <div class="relative my-1">
                <input
                  type="text"
                  id="Category"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Category Tag"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                />

                <span class="absolute inset-y-0 inline-flex items-center right-4">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </span>
              </div>
            </div>
            <div className="">
              <div className="mt-10">
                <label className="text-left mr-5">Start Time:</label>
                <BasicDateTimePicker handler={startTimeHandler} />
              </div>

              <div className="mt-10">
                <label className="mr-5">End Time:</label>
                <BasicDateTimePicker handler={endTimeHandler} />
              </div>
            </div>

            {/* <div>
              <div class="relative mt-1">
                <input
                  type="text"
                  id="imageUrl"
                  class="w-full p-4 pr-12 text-sm border-gray-200 rounded-lg shadow-sm"
                  placeholder="Image URL (https://source.unsplash.com/...)"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                />

                <span class="absolute inset-y-0 inline-flex items-center right-4">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                  />
                </span>
              </div>
            </div> */}
            <div class="dropdown dropdown-hover">
              <label
                tabindex="0"
                class="btn bg-white border-none hover:bg-slate-200 m-1"
              >
                Select Emoji
              </label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow bg-base-white rounded-box w-52"
              >
                <Picker onEmojiClick={onEmojiClick} />
              </ul>
            </div>
            {chosenEmoji ? (
              <span className="">You chose: {chosenEmoji.emoji}</span>
            ) : (
              <span>No emoji Chosen</span>
            )}

            <button
              type="submit"
              class="block w-full px-5 py-3 text-sm font-medium text-white bg-accent-color hover:bg-hover-color rounded-lg"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
