import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState, useEffect } from "react";

export default function BasicDateTimePicker({ handler, timeValue }) {
  const [value, setValue] = useState();

  // useEffect(() => {
  //   setValue(timeValue);
  // }, [timeValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label="DateTimePicker"
        value={value}
        onChange={(newValue) => {
          setValue(handler(newValue));
          setValue(newValue);
        }}
      />
    </LocalizationProvider>
  );
}
