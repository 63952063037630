import React from "react";

export const AdminApproveCard = (props) => {
  const link = `/events/${props.id}`;
  var date = new Date(props.createdAt);

  var fullDate = props.dateToString(date);

  let adminApproved = () => {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ adminApproved: true }),
    };
    fetch(
      `https://loop-ruby.vercel.app/inclineprep/admin/edit/${props.id}`,
      requestOptions
    )
      .then(alert("success"))
      .catch((error) => {
        console.error("there was an error", error);
      });
    console.log("success");
  };

  const deleteFunction = () => {
    fetch(`https://loop-ruby.vercel.app/inclineprep/admin/delete/${props.id}`, {
      method: "DELETE",
    }).catch((error) => console.log(error));
    alert("success");
    console.log("success");
    window.location.reload();
  };

  return (
    <div className="w-full">
      <a
        class="block overflow-hidden rounded-xl shadow-sm h-fit hover:shadow-2xl"
        href={link}
      >
        {/* <img class="object-cover w-full h-56" src={props.image} alt="" /> */}

        <div class="p-6">
          <div class="flex space-x-2 items-center">
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
            <p class="mt-2 text-lg text-left text-gray-600">{props.location}</p>
          </div>
          <div class="flow-root">
            <span className="lg:text-4xl float-left mr-4 text-2xl  " role="img">
              {props.emoji}
            </span>
            <p class="lg:text-4xl text-2xl font-bold float-left">
              {props.title}
            </p>

            <p class="lg:text-4xl text-xl font-bold float-right text-gray-600">
              {fullDate}
            </p>
          </div>
          <div class="flow-root">
            <br />
            {/* <Tag tagName={props.category} /> */}
          </div>
        </div>
        <button onClick={adminApproved} class="btn btn-primary float-left my-2">
          Approve
        </button>
        <button
          onClick={deleteFunction}
          className="btn btn-primary float-left mx-4 my-2 bg-red-500 border-red-500"
        >
          Delete
        </button>
      </a>
    </div>
  );
};
