import React from "react";

export const About = () => {
  return (
    <div>
<section class="text-white bg-gray-900">
  <div class="max-w-screen-xl px-4 py-32 mx-auto lg:h-screen lg:items-center lg:flex">
    <div class="max-w-3xl mx-auto text-center">
      <h1 class="text-5xl font-extrabold">
        About Us
      </h1>
    </div>
  </div>
</section>
<section>
  <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8 sm:py-24">
    <div class="max-w-3xl">
      <h2 class="text-3xl font-bold sm:text-4xl text-left text-white">
        We are a team of university students committed to improving the current education landscape using technology.
      </h2>
    </div>

    <div class="grid grid-cols-1 gap-8 mt-8 lg:gap-16 lg:grid-cols-2">
      <div class="relative h-64 overflow-hidden sm:h-80 lg:h-full">
        <img
          class="absolute inset-0 object-cover w-full h-full"
          src="https://images.unsplash.com/photo-1549057446-9f5c6ac91a04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1034&q=80"
          alt="Man using a computer"
        />
      </div>

      <div class="lg:py-16">
        <article class="space-y-4 text-white text-left">
          <p>
          We are a team of university students from various faculties who have come together for the sole purpose of helping high school students achieve more out of their post-secondary experience.
          </p>

          <p>
          Having gone through the transition from high school into post-secondary education, we recognize the difficulties that students have in adapting to their newfound endeavors and discovering their passions. Through panel discussions, mentorship and blogs, we aim to help high schools students meet their academic and professional goals.
          </p>

          <p>
          That is why we have brought together a team consisting of highly motivated students from various backgrounds and fields of study. We are committed to bringing the best to all students we engage with to ensure that they get the most out of their experience with Incline Education.
          </p>
        </article>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};
