import React, { useEffect, useState } from "react";
import { ClubCard } from "../components/clubCard";
import { LoadingPage } from "../components/LoadingPage";
import { NewLoading } from "../components/NewLoading";

export const ClubsPage = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://loop-ruby.vercel.app/inclineprep/clubs`)
        .then((res) => res.json())
        .then((json) => {
          console.log("data");
          console.log(json);
          setData(json);
          setFilteredData(
            json.filter(function (el) {
              return el.adminApproved === true;
            })
          );
          setLoading(false);
        });
    };
    fetchData().catch(console.error);
  }, []);

  console.log(data);

  if (loading) {
    return <LoadingPage />;
  } else {
    return (
      <div className="bg-gray-900">
        <div className="col-span-9 min-h-screen">
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 mx-4 lg:mx-10 divide-y divide-gray-600">
            {filteredData.map((club) => {
              return (
                <div>
                  <ClubCard
                    clubName={club.clubName}
                    description={club.description}
                    createdAt={club.createdAt}
                    slug={club.slug}
                    category={club.category}
                    emoji={club.emoji}
                    location={club.location}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};
