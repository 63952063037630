import React, { useEffect, useState } from "react";
import { SideBar } from "../components/SideBar";
import { Calendar, DateRange, DateRangePicker } from "react-date-range";
import { Button } from "../components/Button";
import { CalendarEvent } from "./CalendarEvent";
import { LoadingPage } from "../components/LoadingPage";
import "./calendar-styling/styles.css"; // main style file
import "./calendar-styling/default.css"; // theme css file
import { GiHamburgerMenu } from "react-icons/gi";
import { AiTwotoneCalendar } from "react-icons/ai";
import firebase from "firebase/compat/app";
import { InterestPicker } from "../components/InterestPicker";

export const PersonalizedPage = () => {
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState();
  const [isListView, setListView] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [matchedDateList, setMatchedDateList] = useState({});
  const [isDateRange, setDateRange] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [loggedInUser, setUser] = useState();
  const [websiteReady, setWebsiteReady] = useState(false);
  const [userInDatabase, setUserInDatabase] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `https://loop-ruby.vercel.app/inclineprep/events`
      )
        .then((res) => res.json())
        .then((json) => {
          setData(json);
          //   setFilteredData(
          //     json.filter(function (el) {
          //       return el.adminApproved === true;
          //     })
          //   );
          setLoading(false);
        });
    };
    fetchData().catch(console.error);
  }, []);
  //   console.log(data);

  const filterItem = (category) => {
    setFilteredData([]);
    console.log("filtered data");
    console.log(filteredData);
    for (let i = 0; i < category.length; i++) {
      const filteredItem = data.filter((event) => {
        return event.category === category[i] && event.adminApproved === true;
      });
      console.log(filteredItem[0]);
      setFilteredData((oldArray) => [...oldArray, filteredItem[0]]);
    }
    console.log(filteredData);
  };

  // need to figure out way to only run this one time
  if (!isLoading) {
    const fetchUserPersonalization = () => {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          console.log("user activated");
          console.log(user);
          setUser(user);
          setFilteredData([]);
          const data = fetch(
            `https://loop-ruby.vercel.app/inclineprep/userInterests/${user.uid}`
          )
            .then((res) => res.json())
            .then((json) => {
              console.log(json);
              if (json != null) {
                setUserInDatabase(true);
                const category = json.subjects;
                console.log(category);
                filterItem(category);
              } else {
                console.log("no user found");
              }
              console.log(json.subjects);
            });
        } else {
          return;
        }
      });
      setLoading(true);
      setWebsiteReady(true);
      //     console.log(filteredData);
    };
    fetchUserPersonalization();
  }

  // Only shows events on the day that is clicked on the calendar and if it is admin approved
  useEffect(() => {
    if (websiteReady) {
      console.log(startDate);
      console.log(endDate);
      setFilteredData(
        data.filter(function (el) {
          // converts the date in the database to a Date
          console.log(el.createdAt);
          const jsDate = new Date(el.createdAt);
          if (startDate === endDate) {
            return (
              datesAreOnSameDay(jsDate, startDate) && el.adminApproved === true
            );
          } else {
            return (
              jsDate > startDate &&
              jsDate < endDate &&
              el.adminApproved === true
            );
          }
        })
      );
    }
  }, [startDate, endDate]);

  // filters the events when the user types in the search box
  useEffect(() => {
    if (websiteReady) {
      const filteredSearch = data.filter((event) => {
        return event.title.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredData(filteredSearch);
    }
  }, [search]);

  useEffect(() => {
    if (websiteReady) {
      setStartDate(date);
      setEndDate(date);
    }
  }, [date]);

  // personalized feed feature

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  let calendar;
  if (isDateRange) {
    calendar = <DateRange ranges={[selectionRange]} onChange={handleSelect} />;
  } else {
    calendar = <Calendar value={date} onChange={setDate} />;
  }

  //return true if the dates are on the same day
  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  if (!websiteReady) {
    return <LoadingPage />
  }

  if (loggedInUser == null) {
    return (
      <div>
        <div className="bg-gray-900">
          <section class="">
            <div class="px-4 pt-16 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
              <div class="grid grid-cols-1 lg:grid-cols-2">
                <div class="relative z-10 lg:py-16 lg:order-last">
                  <div class="relative h-64 lg:h-full bg-gray-900">
                    {/* <img
                      class="absolute inset-0 object-cover h-76 lg:h-max mx-auto"
                      src={}
                      // src="https://images.unsplash.com/photo-1531497865144-0464ef8fb9a9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
                      alt="Indoors house"
                    /> */}
                  </div>
                </div>
                <div class="relative items-center bg-gray-900">
                  {/* <span class="hidden lg:inset-y-0 lg:absolute lg:w-16 lg:bg-gray-100 lg:block lg:-left-16"></span> */}

                  <div class="p-8 sm:p-16 lg:p-24">
                    <h1 class="sm:text-sm font-extrabold lg:text-3xl text-white">
                      {/* <img className="h-32" src={Logo} /> */}
                      <br />
                      <strong class="text-primary-color sm:block text-4xl lg:text-6xl font-extrabold text-left">
                        No User Logged In
                      </strong>
                    </h1>

                    <p class="max-w-lg mt-4 sm:leading-relaxed sm:text-xl text-left">
                      Please login and save your favourite categories and clubs
                      to continue!
                    </p>

                    {/* <a
                  class="inline-block px-12 py-3 mt-8 text-sm font-medium text-white bg-primary-color border border-primary-color rounded active:text-white hover:bg-yellow-500 hover:text-white focus:outline-none focus:ring"
                  href="/"
                >
                  About Us
                </a> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex mx-auto justify-center items-center">
          <AiOutlineArrowDown />
        </div> */}
          </section>
        </div>
      </div>
    );
  }

  if (!userInDatabase) {
    return (
      <div className="bg-gray-900 w-full">
        <InterestPicker />
      </div>
    );
  }

  // sort the events into a dictionary where the same events are appended into an array
  const sortDates = () => {
    let dateList = {};
    for (let i = 0; i < data.length; i++) {
      if (data[i].createdAt in dateList) {
        dateList[data[i].createdAt].push(data[i]);
      } else {
        dateList[data[i].createdAt] = [];
        dateList[data[i].createdAt].push(data[i]);
      }
    }
    Object.keys(dateList).map((key) => {
      console.log(dateList[key]);
      dateList[key].map((event) => {
        console.log(event);
      });
    });
    setMatchedDateList(dateList);
    console.log(matchedDateList);
    setMatchedDateList(dateList);
    console.log(matchedDateList);
  };

  // function to filter the events in the selected range based on category
  //   const filterItem = (category) => {
  //     setFilteredData([]);
  //     for (let i = 0; i < category.length; i++) {
  //       const filteredItem = data.filter((event) => {
  //         return event.category === category[i];
  //       });
  //       console.log(filteredItem[0]);
  //       setFilteredData((oldArray) => [...oldArray, filteredItem[0]]);
  //     }
  //     console.log("new");
  //     console.log(filteredData);
  //   };

  const dateFilter = (startingDate, endingDate) => {
    setStartDate(startingDate);
    setEndDate(endingDate);
  };

  // function which adds days to date -> used for the filter buttons for the date range
  const addDaysToDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var splitDate = date.toString().split(" ");
    var dt = splitDate[2];
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  const getDayOfWeek = (date) => {
    var splitDate = date.toString().split(" ");
    var dayOfWeek = splitDate[0];
    return dayOfWeek;
  };

  // changes the state of the start and end date when the user clicks
  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  return (
    <div className="bg-gray-900">
      <form className="w-3/4 mx-auto mb-10">
        <label
          for="default-search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
        >
          Search
        </label>
        <div class="relative">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Events"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </form>
      <div className="flex items-center justify-center mb-4 lg:hidden">
        {/* <DateRange ranges={[selectionRange]} onChange={handleSelect} /> */}
        {/* <Calendar className="react-calendar" value={date} onChange={setDate} /> */}

        {calendar}
      </div>
      <div className="grid grid-cols-2 lg:hidden">
        <div className="dropdown dropdown-hover">
          <label
            tabIndex="0"
            className="btn m-1 bg-gray-800 border-none hover:bg-base-300"
          >
            <GiHamburgerMenu className="mr-2" />
            Categories
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full"
          >
            <li>
              {" "}
              <a
                onClick={() => filterItem(["Computer Science", "Biology"])}
                className="lg:w-3/4 w-full"
              >
                Comp-Sci
              </a>
            </li>
            <li>
              <a
                onClick={() => filterItem("biology")}
                className="lg:w-3/4 w-full"
              >
                Biology
              </a>
            </li>
          </ul>
        </div>
        <div className="dropdown dropdown-hover">
          <label tabIndex="0" className="btn m-1 bg-gray-800 border-none">
            <AiTwotoneCalendar className="mr-2" />
            Date Range
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full"
          >
            <button
              onClick={() => dateFilter(date, date)}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none hover:bg-base-300"
            >
              Today
            </button>
            <button
              onClick={() =>
                dateFilter(addDaysToDate(date, 1), addDaysToDate(date, 1))
              }
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none hover:bg-base-300 "
            >
              Tomorrow
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay();
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none hover:bg-base-300"
            >
              This Week
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay() + 7;
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none hover:bg-base-300"
            >
              Next Week
            </button>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 ">
        {/* Side bar */}
        <div className="col-span-3 ml-10 lg:block hidden">
          <div className=" h-screen w-full">
            <div className="flex items-center justify-center mb-10">
              {/* <Calendar
                className="react-calendar"
                value={date}
                onChange={setDate}
              /> */}
              {/* <Calendar date={date} onChange={setDate} /> */}
              {calendar}
              {/* <DateRange ranges={[selectionRange]} onChange={handleSelect} /> */}
            </div>
            <div>
              <label></label>
              <button
                className="btn btn-primary bg-gray-800 border-none text-gray-400 mb-4 hover:bg-base-300"
                onClick={() => setDateRange(!isDateRange)}
              >
                <AiTwotoneCalendar className="mr-2" />
                Calendar View
              </button>
            </div>

            <div className="dropdown dropdown-hover">
              <label
                tabIndex="0"
                className="btn m-1 w-full bg-gray-800 border-none hover:bg-base-300"
              >
                <GiHamburgerMenu className="mr-2" />
                Category Filters
              </label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full"
              >
                <li>
                  <a
                    onClick={() =>
                      setFilteredData(
                        data.filter((event) => {
                          return (
                            new Date(event.createdAt) > date &&
                            event.adminApproved === true
                          );
                        })
                      )
                    }
                  >
                    All Events
                  </a>
                </li>
                <li>
                  {" "}
                  <a onClick={() => filterItem(["Computer Science", "Sports"])}>
                    Comp-Sci
                  </a>
                </li>
                <li>
                  <a onClick={() => filterItem("Public Speaking")}>
                    Public Speaking
                  </a>
                </li>
                <li>
                  <a onClick={() => filterItem("Public Speaking")}>Biology</a>
                </li>
              </ul>
            </div>
            {/* <h2 className="text-xl  text-white">Category Filter</h2>
            <Button text="biology" />
            <button
              onClick={() => filterItem("computer science")}
              className="btn lg:w-3/4 w-full"
            >
              Comp-Sci
            </button>
            <Button href="/sdsdf" text="Math" /> */}

            {/* Uses the date filter function which takes the starting and ending date and sets the state to filter */}
            <h2 className="text-xl mt-10 mb-2 text-white">Date Filter</h2>
            <button
              onClick={() => dateFilter(date, date)}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              Today
            </button>
            <button
              onClick={() =>
                dateFilter(addDaysToDate(date, 1), addDaysToDate(date, 1))
              }
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              Tomorrow
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay();
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              This Week
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay() + 7;
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              Next Week
            </button>
          </div>
        </div>

        {/* Main Section */}
        <div className="col-span-9 mb-10">
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 mx-4 lg:mx-10 divide-y divide-gray-600 ">
            {filteredData.map((event) => {
              return (
                <div>
                  <CalendarEvent
                    title={event.title}
                    description={event.description}
                    createdAt={event.createdAt}
                    dateToString={dateToString}
                    slug={event.slug}
                    image={event.image}
                    category={event.category}
                    emoji={event.emoji}
                    location={event.location}
                    getDayOfWeek={getDayOfWeek}
                    id={event._id}
                  />
                </div>
              );
            })}
          </div>
          {/* <button className="hidden" onClick={sortDates}>
            test
          </button> */}
          {/* <InterestPicker /> */}
        </div>
      </div>
    </div>
  );
};
