import React from "react";
import { Tag } from "../components/Tag";

export const ClubCard = (props) => {
  const link = `/clubs/${props.slug}`;
  const location = props.location ? props.location : "No Location Specified";

  return (
    <div>
      <a
        class="block overflow-hidden rounded-lg shadow-sm lg:h-28 h-fit hover:shadow-2xl"
        href={link}
      >
        {/* <img class="object-cover w-full h-56" src={props.image} alt="" /> */}

        <div class="py-4 pl-6">
          <div class="flex space-x-2 items-center">
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
            <p class="mt-2 text-lg text-left text-gray-600">{location}</p>
          </div>
          <div class="flow-root">
            <span className="lg:text-4xl float-left mr-4 text-2xl  " role="img">
              {props.emoji}
            </span>
            <p class="lg:text-3xl text-2xl font-bold float-left">
              {props.clubName}
            </p>
            <div className="float-right mx-4 lg:block hidden">
              {" "}
              <Tag tagName={props.category} />
            </div>
          </div>
          <div class="flow-root float-right">
            <br />
            <Tag tagName={props.category} />
            {/* <button
              className="btn btn-primary"
              style={{ backgroundColor: dayColor }}
            >
              Test
            </button> */}
          </div>
        </div>
      </a>
    </div>
  );
};
