import React, { useEffect, useState } from "react";
import { SimpleMap } from "../components/Map/SimpleMap";
import { bounceInUp, bounceInLeft, bounceInRight } from "react-animations";
import styled, { keyframes } from "styled-components";
import { ImageUpload } from "../components/ImageUpload";
import BasicDateTimePicker from "../components/BasicDateTimePicker";
import { CalendarEvent } from "./CalendarEvent";
import { LoadingPage } from "../components/LoadingPage";
import { HomeComponent } from "../components/HomeComponent";

export const Home = () => {
  const [date, setDate] = useState(new Date());
  const [filteredData, setFilteredData] = useState();
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `https://loop-ruby.vercel.app/inclineprep/events`
      )
        .then((res) => res.json())
        .then((json) => {
          setData(json);
          setFilteredData(
            json.filter(function (el) {
              return new Date(el.createdAt) > date && el.adminApproved === true;
            })
          );
        });
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, []);

  if (isLoading) {
    return <HomeComponent />;
  }

  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var splitDate = date.toString().split(" ");
    var dt = splitDate[2];
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  const getDayOfWeek = (date) => {
    var splitDate = date.toString().split(" ");
    var dayOfWeek = splitDate[0];
    return dayOfWeek;
  };
  const BounceInUp = styled.div`
    animation: 2s ${keyframes`${bounceInUp}`};
  `;

  const BounceInLeft = styled.div`
    animation: 2s ${keyframes`${bounceInLeft}`};
  `;

  const BounceInRight = styled.div`
    animation: 2s ${keyframes`${bounceInRight}`};
  `;

  return (
    <div>
      <HomeComponent />
      <section class="text-white bg-gray-900">
        <div class="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div class="max-w-lg mx-auto text-center">
            <h2 class="text-3xl font-bold sm:text-4xl">Upcoming Events</h2>
          </div>

          <div class="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-1">
            {filteredData.map((event) => {
              return (
                <div>
                  <CalendarEvent
                    title={event.title}
                    description={event.description}
                    createdAt={event.createdAt}
                    dateToString={dateToString}
                    slug={event.slug}
                    image={event.image}
                    category={event.category}
                    emoji={event.emoji}
                    location={event.location}
                    getDayOfWeek={getDayOfWeek}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* </BounceInRight> */}
    </div>
  );
};
