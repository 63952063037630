import React, { useEffect, useState } from "react";
import { SideBar } from "../components/SideBar";
import { Calendar, DateRange, DateRangePicker } from "react-date-range";
import { Button } from "../components/Button";
import { CalendarEvent } from "./CalendarEvent";
import { LoadingPage } from "../components/LoadingPage";
import "./calendar-styling/styles.css"; // main style file
import "./calendar-styling/default.css"; // theme css file
import { GiHamburgerMenu } from "react-icons/gi";
import { AiTwotoneCalendar } from "react-icons/ai";
import { DayOfEvents } from "../components/DayOfEvents";

export const NewCalendar = () => {
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState();
  const [isListView, setListView] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const [matchedDateList, setMatchedDateList] = useState({});
  const [isDateRange, setDateRange] = useState(false);
  const [uniqueDates, setUniqueDates] = useState();
  const [uniqueDatesKeys, setUniqueDatesKeys] = useState();
  const [uniqueDatesLoaded, setUniqueDatesLoaded] = useState(false);

  // grouping events that are on the same day
  const groupDatesOnSameDay = () => {
    console.log(filteredData);
    var uniqueDatesList = {};
    for (let i = 0; i < filteredData.length; i++) {
      let eventDate = filteredData[i].createdAt.substring(0, 10);
      if (!(eventDate in uniqueDatesList)) {
        uniqueDatesList[eventDate] = [filteredData[i]];
      } else {
        uniqueDatesList[eventDate].push(filteredData[i]);
      }
    }
    console.log("testing");
    console.log(uniqueDatesList);
    setUniqueDates(uniqueDatesList);
    setUniqueDatesKeys(Object.keys(uniqueDatesList));
    // console.log(uniqueDates[uniqueDatesKeys[0]]);
    setUniqueDatesLoaded(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `https://loop-ruby.vercel.app/inclineprep/events`
      )
        .then((res) => res.json())
        .then((json) => {
          setData(json);
          setFilteredData(
            json.filter(function (el) {
              if (el.startTime == null) {
                return (
                  new Date(el.createdAt) > date && el.adminApproved === true
                );
              } else {
                return (
                  new Date(el.startTime) > date && el.adminApproved === true
                );
              }
            })
          );
          setLoading(false);
        });
    };
    fetchData().catch(console.error);
  }, []);

  console.log(data);

  // Only shows events on the day that is clicked on the calendar and if it is admin approved
  useEffect(() => {
    if (!isLoading) {
      console.log(startDate);
      console.log(endDate);
      setFilteredData(
        data.filter(function (el) {
          // converts the date in the database to a Date
          console.log(el.createdAt);
          const jsDate = new Date(el.createdAt);
          if (startDate === endDate) {
            return (
              datesAreOnSameDay(jsDate, startDate) && el.adminApproved === true
            );
          } else {
            return (
              jsDate > startDate &&
              jsDate < endDate &&
              el.adminApproved === true
            );
          }
        })
      );
    }
  }, [startDate, endDate]);

  // groups the data for the current filtered data array
  useEffect(() => {
    if (filteredData != null) {
      groupDatesOnSameDay();
    }
  }, [filteredData]);

  // filters the events when the user types in the search box
  useEffect(() => {
    if (!isLoading) {
      const filteredSearch = data.filter((event) => {
        return event.title.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredData(filteredSearch);
    }
  }, [search]);

  useEffect(() => {
    if (!isLoading) {
      setStartDate(date);
      setEndDate(date);
    }
  }, [date]);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  let calendar;
  if (isDateRange) {
    calendar = <DateRange ranges={[selectionRange]} onChange={handleSelect} />;
  } else {
    calendar = <Calendar value={date} onChange={setDate} />;
  }

  //return true if the dates are on the same day
  const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

  if (isLoading) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }

  // sort the events into a dictionary where the same events are appended into an array
  const sortDates = () => {
    let dateList = {};
    for (let i = 0; i < data.length; i++) {
      if (data[i].createdAt in dateList) {
        dateList[data[i].createdAt].push(data[i]);
      } else {
        dateList[data[i].createdAt] = [];
        dateList[data[i].createdAt].push(data[i]);
      }
    }
    Object.keys(dateList).map((key) => {
      console.log(dateList[key]);
      dateList[key].map((event) => {
        console.log(event);
      });
    });
    setMatchedDateList(dateList);
    console.log(matchedDateList);
    setMatchedDateList(dateList);
    console.log(matchedDateList);
  };

  // function to filter the events in the selected range based on category
  const filterItem = (category) => {
    console.log(category);
    const filteredItem = data.filter((event) => {
      return event.category === category;
    });
    setFilteredData(filteredItem);
  };

  const dateFilter = (startingDate, endingDate) => {
    setStartDate(startingDate);
    setEndDate(endingDate);
  };

  // function which adds days to date -> used for the filter buttons for the date range
  const addDaysToDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var splitDate = date.toString().split(" ");
    var dt = splitDate[2];
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  const getDayOfWeek = (date) => {
    var splitDate = date.toString().split(" ");
    var dayOfWeek = splitDate[0];
    return dayOfWeek;
  };

  // changes the state of the start and end date when the user clicks
  function handleSelect(ranges) {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  }

  const getContent = () => {
    if (uniqueDatesKeys != null) {
      let content = [];
      console.log(uniqueDates);
      for (let i = 0; i < Object.keys(uniqueDates).length; i++) {
        content.push(
          <DayOfEvents
            date={uniqueDatesKeys[i]}
            filteredData={uniqueDates[uniqueDatesKeys[i]]}
          />
        );
      }
      console.log("here");
      console.log(content);
      return content;
    }
  };

  return (
    <div className="min-h-fit bg-gray-900">
      <form className="w-3/4 mx-auto mb-10">
        <label
          for="default-search"
          class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
        >
          Search
        </label>
        <div class="relative">
          <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              class="w-5 h-5 text-gray-500 dark:text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            type="search"
            id="default-search"
            class="block p-4 pl-10 w-full text-sm rounded-xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Events"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </form>
      <div className="flex items-center justify-center mb-4 lg:hidden">
        {/* <DateRange ranges={[selectionRange]} onChange={handleSelect} /> */}
        {/* <Calendar className="react-calendar" value={date} onChange={setDate} /> */}

        {calendar}
      </div>
      <div className="grid grid-cols-2 lg:hidden">
        <div className="dropdown dropdown-hover">
          <label
            tabIndex="0"
            className="btn m-1 bg-base-200 border-none hover:bg-base-300"
          >
            <GiHamburgerMenu className="mr-2" />
            Categories
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full"
          >
            <li>
              {" "}
              <a
                onClick={() => filterItem("Computer Science")}
                className="lg:w-3/4 w-full"
              >
                Comp-Sci
              </a>
            </li>
            <li>
              <a
                onClick={() => filterItem("biology")}
                className="lg:w-3/4 w-full"
              >
                Biology
              </a>
            </li>
          </ul>
        </div>
        <div className="dropdown dropdown-hover">
          <label tabIndex="0" className="btn m-1 bg-base-200 border-none">
            <AiTwotoneCalendar className="mr-2" />
            Date Range
          </label>
          <ul
            tabindex="0"
            class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full"
          >
            <button
              onClick={() => dateFilter(date, date)}
              className="btn lg:w-3/4 w-full mb-2 border-none hover:bg-base-300"
            >
              Today
            </button>
            <button
              onClick={() =>
                dateFilter(addDaysToDate(date, 1), addDaysToDate(date, 1))
              }
              className="btn lg:w-3/4 w-full mb-2 bg-base-200 border-none hover:bg-base-300 "
            >
              Tomorrow
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay();
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-base-300 border-none hover:bg-base-300 "
            >
              This Week
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay() + 7;
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-base-200 border-none hover:bg-base-300"
            >
              Next Week
            </button>
          </ul>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-12 ">
        {/* Side bar */}
        <div className="col-span-3 ml-10 lg:block hidden">
          <div className=" h-screen w-full">
            <div className="flex items-center justify-center mb-10">
              {/* <Calendar
                className="react-calendar"
                value={date}
                onChange={setDate}
              /> */}
              {/* <Calendar date={date} onChange={setDate} /> */}
              {calendar}
              {/* <DateRange ranges={[selectionRange]} onChange={handleSelect} /> */}
            </div>
            <div>
              <label></label>
              <button
                className="btn btn-primary bg-gray-800 border-none text-gray-400 mb-4 hover:bg-base-300"
                onClick={() => setDateRange(!isDateRange)}
              >
                <AiTwotoneCalendar className="mr-2" />
                Calendar View
              </button>
            </div>

            <div className="dropdown dropdown-hover">
              <label
                tabIndex="0"
                className="btn m-1 w-full bg-gray-800 border-none hover:bg-base-300"
              >
                <GiHamburgerMenu className="mr-2" />
                Category Filters
              </label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full"
              >
                <li>
                  <a
                    onClick={() =>
                      setFilteredData(
                        data.filter((event) => {
                          return (
                            new Date(event.createdAt) > date &&
                            event.adminApproved === true
                          );
                        })
                      )
                    }
                  >
                    All Events
                  </a>
                </li>
                <li>
                  {" "}
                  <a onClick={() => filterItem("Computer Science")}>Comp-Sci</a>
                </li>
                <li>
                  <a onClick={() => filterItem("Public Speaking")}>
                    Public Speaking
                  </a>
                </li>
                <li>
                  <a onClick={() => filterItem("Public Speaking")}>Biology</a>
                </li>
              </ul>
            </div>
            {/* <h2 className="text-xl  text-white">Category Filter</h2>
            <Button text="biology" />
            <button
              onClick={() => filterItem("computer science")}
              className="btn lg:w-3/4 w-full"
            >
              Comp-Sci
            </button>
            <Button href="/sdsdf" text="Math" /> */}

            {/* Uses the date filter function which takes the starting and ending date and sets the state to filter */}
            {/* <div className="dropdown dropdown-hover mt-2 mx-10">
              <label
                tabIndex="0"
                className="btn m-1 w-full bg-base-200 border-none hover:bg-base-300"
              >
                <GiHamburgerMenu className="mr-2" />
                Date Filter
              </label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-full"
              ></ul>
            </div> */}
            <h2 className="text-xl mt-10 mb-2 text-white">Date Filter</h2>
            <button
              onClick={() => dateFilter(date, date)}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              Today
            </button>
            <button
              onClick={groupDatesOnSameDay}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none hover:bg-base-300"
            >
              Test Button
            </button>
            <button
              onClick={() =>
                dateFilter(addDaysToDate(date, 1), addDaysToDate(date, 1))
              }
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              Tomorrow
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay();
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              This Week
            </button>
            <button
              onClick={() => {
                var curr = new Date();
                curr.setHours(0, 0, 0, 0);
                var first = curr.getDate() - curr.getDay() + 7;
                var last = first + 6;
                var firstday = new Date(curr.setDate(first));
                var lastday = new Date(curr.setDate(last));
                console.log(firstday);
                console.log(lastday);
                dateFilter(firstday, lastday);
              }}
              className="btn lg:w-3/4 w-full mb-2 bg-gray-800 border-none text-black dark:text-gray-400 hover:bg-base-300"
            >
              Next Week
            </button>
          </div>
        </div>

        {/* Main Section */}
        <div className="col-span-9 mb-10">
          <div className="grid grid-cols-1 lg:grid-cols-1 gap-6 mx-4 lg:mx-10 divide-y divide-gray-600 ">
            {getContent()}
          </div>
        </div>
      </div>
    </div>
  );
};
