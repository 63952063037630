import React, { useEffect, useState } from "react";
import { CalendarEvent } from "./CalendarEvent";

export const AgendaView = () => {
  const [date, setDate] = useState(new Date());
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(
        `https://loop-ruby.vercel.app/inclineprep/events`
      )
        .then((res) => res.json())
        .then((json) => setData(json));
      setLoading(false);
    };
    fetchData().catch(console.error);
  }, []);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  console.log(date);

  const dateToString = (date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();
    var fullDate = `${year}-${month}-${dt}`;
    return fullDate;
  };

  // Only shows events on the day that is clicked on the calendar and if it is admin approved
  var testing = data.filter(function (el) {
    return (
      new Date(el.createdAt) - new Date(date) > 0
      // && el.adminApproved == true
    );
  });

  console.log(testing);

  return (
    <div>
      <div>
        <h1 class="text-5xl my-10">Upcoming Events </h1>
        <div class="max-w-screen-xl py-5 mx-auto flex lg:items-center">
          <div class="mx-auto text-center">
            <div class="grid lg:grid-cols-1 lg:mx-28 mx-2 sm:grid-cols-1 gap-10">
              {testing.map((event) => (
                <CalendarEvent
                  title={event.title}
                  description={event.description}
                  createdAt={event.createdAt}
                  dateToString={dateToString}
                  slug={event.slug}
                  image={event.image}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
