import React from "react";

export const Tag = (props) => {
  if (!props.tagName) {
    return <div></div>;
  }
  return (
    <span class="float-right mx-1 justify-between items-center text-xs sm:text-sm bg-gray-200 dark:bg-gray-700 rounded px-4 py-2 font-bold leading-loose cursor-pointer dark:text-gray-300">
      {props.tagName}
    </span>
  );
};
